<template>
  <div v-if="!loading">
    <v-row>
      <v-col>
        <span class="text-h4 mb-5 primary--text">ใบเสร็จรับเงิน</span>

        <span class="primary--text ml-10" v-if="form.number">
          <v-icon class="mb-2" color="primary" left>mdi-file</v-icon>
          {{ form.number }}
        </span>
      </v-col>

      <v-col class="text-right">
        <v-btn class="ml-5" small color="grey" outlined @click="close()">
          ปิดหน้าต่าง
        </v-btn>
        <!--   <PrintDocument :docTypeId="docTypeId" :type="docType" :data="dataPrint.main_bill" :data2="dataPrint.split_bill" ref="printDocument" :disabled="!canPrint">
          <v-btn
            class="ml-5"
            small
            color="primary"
            outlined
            :disabled="!canPrint"
          >
            พิมพ์ <v-icon right>mdi-printer</v-icon>
          </v-btn>
        </PrintDocument> -->

        <v-btn
          class="ml-5"
          small
          color="error"
          outlined
          @click="pay"
          :disabled="form.status != 0 || !isEdit || !$route.params.id"
        >
          จ่ายเงิน
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <span class="text-h4 dark--text mr-5">รวมจำนวนเงินทั้งสิ้น</span>
        <span class="text-h2 primary--text">
          {{ net_price | numeral('0,0.00') }}
        </span>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="approvals"
          :items="list.approval"
          :search-input.sync="form.approval"
          return-object
          item-text="name"
          item-value="_id"
          outlined
          dense
          label="ชื่อพนักงาน"
          autocomplete="off"
          @change="(val) => {getApprovalById(val._id)}"
          auto-select-first
          hide-details
          @keydown="handleApproval()"
          disabled
          class="mb-6"
        >
          <template slot="item" slot-scope="{ item }"> {{item.name}}</template>
        </v-autocomplete>
        <!-- <v-text-field
          name=""
          label="ชื่อพนักงาน"
          :value="form.approval"
          outlined
          dense
          :disabled="isUpdate"
        /> -->
        <v-text-field
          name="coupon_code"
          label="คูปองส่วนลด"
          :value="form.coupon_code"
          outlined
          dense
          disabled
        />
      </v-col>
      <v-col cols="4" offset="1"></v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="5">
            <v-row class="border-white" dense>
              <v-col cols="12">วันที่ออกใบ</v-col>
              <v-col cols="12">วันครบกำหนด</v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row class="border-grey" dense>
              <v-col cols="6">
                {{ formatDate(form.date_of_issue) }}
              </v-col>
              <v-col cols="6" class="pl-5">
                {{ form.date_of_issue | moment($formatTime) }}
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu_due_date"
                  v-model="menu.due_date"
                  :close-on-content-click="false"
                  :return-value.sync="form.due_date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  disabled
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      formatDate(form.due_date)
                    }}</span>
                  </template>
                  <v-date-picker
                    v-model="form.due_date"
                    no-title
                    scrollable
                    locale="th"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-text class="px-5">
            <div class="text-h5 primary--text mb-5">ข้อมูลลูกค้า</div>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="customer"
                  :items="list.customers"
                  :search-input.sync="form.customer_name"
                  return-object
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="ชื่อร้าน"
                  autocomplete="off"
                  @change="val => { getCustomerById(val._id)}"
                  hide-details
                  disabled
                >
                  <template slot="item" slot-scope="{ item }">
                    {{ item.name }}
                  </template>
                </v-autocomplete>
                <!-- <v-text-field
                  v-model="form.customer_name"
                  name="customer_name"
                  return-object
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="ชื่อร้าน"
                  auto-select-first
                  hide-details
                  :disabled="isUpdate"
                >
                  <template slot="item" slot-scope="{ item }"> {{item.customer_name}}</template>
                </v-text-field> -->
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="text-color"
                  name="customer_phone_number"
                  id="customer_phone_number"
                  v-model="form.customer_phone_number"
                  label="เบอร์โทร"
                  outlined
                  dense
                  :reverse="false"
                  hide-details
                  disabled
                  @keypress.native="isNumber"
                  @keydown="preventDelete"
                  @input="parsePhonePattern"
                />
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="6">
                <v-text-field
                  name="name_for_receipt"
                  v-model="form.name_for_receipt"
                  label="ชื่อออกใบเสร็จ"
                  outlined
                  dense
                  disabled
                  hide-details
                  autocomplete="off"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  name="customer_address"
                  label="ที่อยู่"
                  outlined
                  dense
                  autocomplete="off"
                  @click="
                    (dialogAddressData = form.customer_address),
                      (dialogAddress = true),
                      (dialogAddressKey = 'customer_address')
                  "
                  :value="
                    `${form.customer_address.address} ${form.customer_address.sub_district} ${form.customer_address.district} ${form.customer_address.province} ${form.customer_address.postal_code}`
                  "
                  hide-details
                  disabled
                />
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="6">
                <v-text-field
                  name="customer_taxpayer_number"
                  v-model="form.customer_taxpayer_number"
                  label="เลขที่เสียภาษี"
                  outlined
                  dense
                  hide-details
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  name="customer_note"
                  v-model="form.customer_note"
                  label="หมายเหตุลูกค้า"
                  outlined
                  dense
                  rows="3"
                  hide-details
                  disabled
                />
              </v-col>
              <!--  -->

              <!--  -->
              <v-col cols="6">
                <v-text-field
                  v-model="form.customer_company_branch"
                  label="สาขา"
                  outlined
                  dense
                  hide-details
                  disabled
                />
              </v-col>
              <v-col cols="6" />
              <v-col cols="6">
                <v-text-field
                  name="customer_company_branch"
                  v-model="form.customer_company_branch"
                  label="กลุ่มลูกค้า"
                  outlined
                  dense
                  hide-details
                  disabled
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card> </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="px-5">
            <div class="text-h5 primary--text mb-5" >ข้อมูลสินค้า</div>
            <v-card elevation="1" class="mt-5">
              <v-card elevation="0" class="mt-12">
                <v-simple-table v-if="!isLoadingProduct">
                  <template v-slot:default>
                    <thead class="light">
                      <tr>
                        <th class="text-center" style="width:40px;" id="">
                          ลำดับ
                        </th>
                        <th class="text-center" style="width:30%;" id="">
                          ชื่อสินค้า
                        </th>
                        <th class="text-center" style="width:20%;" id="">
                          แบบ
                        </th>
                        <th class="text-center" style="width:150px;" id="">
                          วันเริ่มต้น
                        </th>
                        <th class="text-center" style="width:150px;" id="">
                          วันสิ้นสุด
                        </th>
                        <th class="text-center" id="">ราคา</th>
                        <th class="text-center" id="" style="width:100px;">
                          ส่วนลด
                        </th>
                        <th class="text-center" id="" style="width:40px;">
                          Vat %
                        </th>
                        <th class="text-center" id="" style="width:100px;">ราคารวม</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in form.product" :key="index">
                        <td class="text-center px-1 my-3 table-style">
                          {{ index + 1 }}.
                        </td>
                        <td class="px-1 table-style">
                          <v-autocomplete
                            v-model="item.name"
                            v-bind:items="list.product.data"
                            return-object
                            item-text="name"
                            outlined
                            dense
                            solo
                            filled
                            autocomplete="off"
                            auto-select-first
                            hide-details
                            disabled
                            class="my-3"
                          >
                          </v-autocomplete> 
                        </td>
                        <td class="px-1 table-style">
                          <v-select
                            class="my-3"
                            outlined
                            dense
                            background-color="white"
                            :items="list.product_type"
                            v-model="item.product_type"
                            item-value="value"
                            item-text="name"
                            hide-details
                            disabled
                          ></v-select>
                        </td>
                        <td class="px-1">
                          <DatePicker
                            label="วันเริ่มต้น"
                            :value="item.start_date"
                            @onChange="val => ((item.start_date = val))"
                            hideDetails
                            disabled
                          />
                        </td>
                        <td class="px-1">
                          <DatePicker
                            label="วันสิ้นสุด"
                            :value="item.end_date"
                            @onChange="val => ((item.end_date = val))"
                            hideDetails
                            disabled
                          />
                        </td>
                        <td class="px-1 table-style">
                          <v-text-field
                            class="my-3"
                            outlined
                            dense
                            :value="item.product_price | numeral('0,0.00')"
                            disabled
                            @change="value => handleProductPrice(index, 'product_price', value )"
                            @focus="$event.target.select()"
                            :hide-details="true"
                          /> 
                        </td>
                        <td class="px-1 table-style">
                          <v-text-field
                            class="my-3"
                            outlined
                            dense
                            :value="item.discount | numeral('0,0.00')"
                            disabled
                            @change="value => handleProductPrice(index, 'discount', value)"
                            @focus="$event.target.select()"
                            :hide-details="true"
                          /> 
                        </td>
                        <td class="px-1 table-style">
                          <v-select
                            class="my-3"
                            outlined
                            dense
                            background-color="white"
                            :items="list.vat_dict"
                            v-model="item.vat"
                            item-value="value"
                            item-text="name"
                            hide-details
                            disabled
                            @change="
                              value => handleProductPrice(index, 'vat', value)
                            "
                          ></v-select>
                        </td>
                        <td class="px-1 table-style">
                          <v-text-field
                            class="my-3"
                            outlined
                            dense
                            :value="item.price | numeral('0,0.00')"
                            disabled
                            :hide-details="true"
                          /> 
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-card-text class="pt-4">
                  <v-row justify="space-between">
                    <v-col cols="4">
                      <v-textarea
                        name="note"
                        class="mt-10"
                        v-model="form.note"
                        label="หมายเหตุท้ายใบเสร็จ"
                        outlined
                        dense
                        disabled
                      />
                    </v-col>
                    <v-col cols="6" md="5" lg="4" xl="3" class="pr-7 fz-3">
                      <div class="mb-3">
                        <span>รวมเป็นเงิน</span>
                        <span class="float-right">
                          {{ total_price | numeral('0,0.00') }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <span>ส่วนลดรวม</span>
                        <span class="float-right">
                          {{ total_discount | numeral('0,0.00') }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <span>มูลค่าแพ็กเกจคงเหลือ</span>
                        <span class="float-right">{{ 0.0 }}</span>
                      </div>
                      <div class="mb-3">
                        <span>ราคาหลังหักส่วนลด</span>
                        <span class="float-right">
                          {{ (total_price - total_discount) | numeral('0,0.00') }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <span>ภาษีมูลค่าเพิ่ม 7%</span>
                        <span class="float-right">
                          {{ vat_price | numeral('0,0.00') }}
                        </span>
                      </div>
                      <div class="mb-3" v-if="withholding">
                        <span>หัก ณ ที่จ่าย 3%</span>
                        <span class="float-right">
                          {{ withholding | numeral('0,0.00') }}
                        </span>
                      </div>

                      <div class="mb-3">
                        <span>จำนวนเงินรวมทั้งสิ้น</span>
                        <span class="float-right">
                          {{ net_price | numeral('0,0.00') }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
            <div v-if="this.$route.params.id && form.payment.type != 0">
              <div class="green--text fz-6 mt-10">บันทึกการชำระเงิน</div>

              <v-card elevation="1" class="mt-5">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="green">
                      <tr>
                        <th id="" class="text-center white--text">
                          วันที่ชำระ
                        </th>
                        <th id="" class="text-center white--text">ช่องทาง</th>
                        <th id="" class="text-center white--text">
                          ยอดรับชำระ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          {{ form.payment.date | moment($formatDate) }}
                          {{ form.payment.date | moment($formatTime) }}
                        </td>
                        <td class="text-center">
                          {{ form.payment.type == 1 ? 'โอนผ่าน QR Code' : '' }}
                          {{ form.payment.type == 2 ? 'บัตรเครดิต' : '' }}
                          {{ form.payment.type == 3 ? 'โอนผ่านเลขบัญชี' : '' }}
                        </td>
                        <td class="text-center">
                          {{ form.payment.amount | numeral('0,0.00') }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="800" v-model="dialogPay">
      <!-- <Pay @onClose="closePay" :id="form._id" /> -->
    </v-dialog>

    <v-dialog persistent scrollable max-width="800" v-model="dialogAlert">
      <v-card class="py-5">
        <v-card-text class="popup-background">
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <div class="text-h5 mt-5" v-for="(msg, i) in list.alert" :key="i">
                {{ msg }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-5">
          <v-spacer />
          <slot></slot>
          <v-btn
            outlined
            class="px-5 mr-10"
            color="grey"
            @click="dialogAlert = false"
          >
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import AddressDetail from '@/components/Address/AddressDetail.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import Pay from './Pay.vue';

import { required, numeric, minValue } from 'vuelidate/lib/validators';

import * as moment from 'moment';

export default {
  components: {
    PrintDocument,
    AddressDetail,
    DatePicker,
    TimePicker,
    Pay
  },

  data: () => ({
    filter: {
      item_per_page: 10,
      page: 1
    },
    selectStart: -1,
    dialogPayChoose: false,
    current_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
    dataPrint: {
      main_bill: null,
      split_bill: null
    },
    dialogPay: '',
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAlert: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: ''
    },
    menu: {
      due_date: false
    },
    is_full_year: false,
    approvals: [],
    customer: [],
    form: {
      _id: '',
      approval: '',
      approval_id: '',
      number: '',
      coupon_code: '',
      customer_address: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: ''
      },
      customer_company_branch: '',
      customer_id: '',
      customer_name: '',
      customer_note: '',
      customer_phone_number: '',
      customer_taxpayer_number: '',
      company_group_id:'',
      date_of_issue: '',
      due_date: '',
      is_withholding: false,
      name_for_receipt: '',
      net_price: 0,
      note: '',
      number: '',
      payment: {
        amount: 0,
        date: moment().set({ hour:0, minute:0, second:0 }).format('YYYY-MM-DDTHH:mm:ss'),
        note: '',
        type: 0
      },
      product: [
        {
          _id: '',
          name: '',
          product_id: '',
          category: 0,
          product_type: 0,
          period: 0,
          price: 0,
          discount: 0,
          vat: 0,
          is_unlimit: 0,
          bills: 0,
          is_reuse_limit: 0,
          can_upgrade: 0,
          is_available: 0,
          product_price: 0,
          end_date: moment().add(365, 'days').set({ hour:0, minute:0, second:0 }).format('YYYY-MM-DDTHH:mm:ss'),
          start_date: moment().set({ hour:0, minute:0, second:0 }).format('YYYY-MM-DDTHH:mm:ss'),
        }
      ],
      status: 0,
      total_price: 0,
      total_discount: 0,
      withholding: 0,
      discounted_groups_id: ''
    },
    list: {
      product_type: [
        { value: 0, name: 'ครั้งเดียว' },
        { value: 1, name: 'รายเดือน' },
        { value: 2, name: 'รายปี' }
      ],
      provinces: [],
      product: [],
      vat_dict: [
        { value: 0, name: '0%' },
        { value: 1, name: '7%' }
      ],
      setting: null,
      discounted_groups: [],
      approval: [],
      customers: [],
    },
    loading: true,
    loadingPrint: true,
    customer: null,
    temp_customer: {
      _id: '',
      name: ''
    },
    isEdit: true,
    isUpdate: false,
    canPrint: false,
    isLoadingProduct: false,
    valid: false,
    errors: {},
    item_per_page: 20,
    page: 1
  }),
  validations: {
    form: {
      project_name: { required },
      customer_name: { required }
    }
  },
  async created() {
    this.loading = true;
    if (!this.$store.state.profile.name) {
      //      window.location.reload()   บรรทัดนี้มีปัญหา
    }
    // this.getYears();
    await this.getProduct();
    await this.getApproval();
    await this.getCustomers();
    this.isUpdate = this.$route.params.id ? true : false;
    if (this.isUpdate) {
      await this.getData();
      if (this.form.status == 2 || this.form.status == 1) {
        this.isEdit = false;
      }
    } else {
      await this.getApprovalById(this.$store.state.profile._id)
      this.form.approval = this.$store.state.profile.name;
      this.form.date_of_issue = moment().format('YYYY-MM-DDTHH:mm:ss');
      await this.getProduct();
      this.form.due_date = moment().add(30, 'days').set({ hour:0, minute:0, second:0 }).format('YYYY-MM-DDTHH:mm:ss');
    }

    if (this.form.status == 0) this.isUpdate = false;

    this.loading = false;

    if (this.$route.query.print == 'true' ||this.$route.query.download == 'true' || this.$route.query.print == true || this.$route.query.download == true ) {
      await setTimeout(() => {}, 1000);
      this.$refs.printDocument.print();
    } else if (this.$route.query.pay == 'true' || this.$route.query.pay == true) {
      this.$router.push({ name: 'package-payment-pay', params: { id: this.form._id }});
      // this.dialogPayChoose = true;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.customer_phone_number.length >= 11 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    preventDelete(evt) {
      let arrowKey = [37, 38, 39, 40];
      let selectStart = evt.target.selectionStart;
      let selectEnd = evt.target.selectionEnd;
      if (evt.keyCode === 8 || evt.keyCode === 46) {
        if (evt.target.value[selectStart - 1] == '-') {
          setTimeout(
            function() {
              this.form.customer_phone_number = this.parsePhonePattern(evt.target.value.slice(0, selectStart - 2) + 
                  evt.target.value.slice( selectStart - 1, evt.target.value.length), true);
              evt.target.value = this.parsePhonePattern(evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(selectStart - 1, evt.target.value.length), true);
            }.bind(this),
            0
          );
        }
      } else {
        if (evt.target.value.length >= 12 && !arrowKey.includes(evt.keyCode)) {
          evt.preventDefault();
        } else {
          this.selectStart = -1;
        }
      }
    },
    parsePhonePattern(value, returnValue = false) {
      let position = value
        .substr(0, 12)
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,7})/);
      let phoneNumber = '';
      phoneNumber = position[1];

      if (position[1].length >= 3) {
        phoneNumber += '-';
      }

      phoneNumber += position[2];

      if (returnValue) {
        return phoneNumber;
      } else {
        this.form.customer_phone_number = phoneNumber;
        if (this.selectStart > -1) {
          let input = document.getElementById('customer_phone_number');
          let selectionStart = this.selectStart;
          window.setTimeout(function() {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart);
          }, 0);
        } else if (window.event.target.selectionStart < value.length) {
          let input = document.getElementById('customer_phone_number');
          let selectionStart = window.event.target.selectionStart;
          window.setTimeout(function() {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart, 'forward');
          }, 0);
        }
      }
    },
    async getData(id = this.$route.params.id) {
      let body = {
        token: this.$jwt.sign({ bill_id: this.$route.params.id }, this.$privateKey,{ noTimestamp: true })
      };
      await this.$axios.post(`${this.$baseUrl}/backend/bill/get_by_id`, body)
        .then(async res => {
          this.form = { ...res.result };
          console.log(this.form)
          await this.getProduct();
          await this.getApprovalById(this.form.approval_id);
          await this.getCustomerById(this.form.customer_id);
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    async getApproval(text = "") {
      let filter = {
        item_per_page: 10,
        page: 1,
        start_with: text,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/backend/users/get_user`, body)
        .then(res=>{
          this.list.approval = res.result.data;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        })
    },
    async getCustomers(text = '') {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        start_with: text
      };
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios.post(`${this.$baseUrl}/backend/customer/get_customer_list`, body)
        .then(res => {
          this.list.customers = res.result;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getApprovalById(approval_id) {
      this.form.approval_id = approval_id;
      let body = {
        token: this.$jwt.sign({user_id : approval_id}, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/backend/users/get_by_id`, body)
        .then(async (res) => {
          let data = res.result;
          if(data){
            this.approvals = data;
          
            this.form.approval_id = data._id;
            this.form.approval = data.name;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    async getCustomerById(customer_id) {
      this.form.customer_id = customer_id;
      let body = {
        token: this.$jwt.sign({ customer_id: customer_id }, this.$privateKey, {noTimestamp: true})
      };
      await this.$axios.post(`${this.$baseUrl}/backend/bill/get_customer_by_id`, body)
        .then(async res => {
          let data = res.result;
          if (data) {
            this.customer = data;
            this.list.customers.push({
              '_id': this.customer._id,
              'name': this.customer.name
            })
            this.form.customer_name = data.name;
            this.form.customer_address = data.address;
            this.form.customer_phone_number = this.parsePhonePattern(data.phone_number, true);
            this.form.customer_taxpayer_number = data.taxpayer_number;
            this.form.customer_company_branch = data.company_branch;
            this.form.customer_note = data.note;
            this.form.company_group_id = data.company_group_id;
            this.form.is_withholding = data.is_withholding;
            this.form.name_for_receipt = data.name_for_receipt;
            if (data.address == '') {
              this.form.customer_address = {
                address: '',
                district: '',
                sub_district: '',
                province: '',
                postal_code: ''
              };
            }
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    },
    pay() {
      this.$router.push({name: 'package-payment-pay',params: { id: this.form._id }});
    },
    close() {
      this.$router.push({ name: `backoffice-receipt` });
    },
    onSubmitAddress(val) {
      this.form[this.dialogAddressKey] = val;
      this.dialogAddress = false;
    },
    async getProduct() {
      let filter = {
        item_per_page: this.filter.item_per_page,
        page: this.filter.page
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios.post(`${this.$baseUrl}/backend/product/get_product`, body)
        .then(async res => {
          this.list.product = res.result;
        });
    },
    handleApproval(){
      setTimeout(() => { 
        let text = this.form.approval || "";
        if(String(text).length == 0){
          this.approvals = null;

          this.form.approval_id = "";
          this.form.approval = "";
        }
        this.getApproval(text);
      }, 250);
    },
    handleProductPrice(index, key, val) {
      let discount = parseFloat(this.form.product?.[index]?.discount || 0);
      let vat = parseFloat(this.form.product?.[index]?.vat || 0);
      let product_price = parseFloat(
        this.form.product?.[index]?.product_price || 0
      );
      let price = 0;

      if (key == 'discount' && val) {
        discount = parseFloat(val);
      } else if (key == 'product_price' && val) {
        product_price = parseFloat(val);
      }

      price =
        vat === 1
          ? product_price - discount + (product_price - discount) * 0.07
          : product_price - discount;

      this.form.product[index].product_price = parseFloat(
        product_price
      ).toFixed(2);
      this.form.product[index].discount = parseFloat(discount).toFixed(2);
      this.form.product[index].price = parseFloat(price).toFixed(2);
      this.form.product[index].vat_price = parseFloat(
        vat === 1 ? (product_price - discount) * 0.07 : 0
      ).toFixed(2);
    },
  },
  watch: {
    '$route.query.pay': function(newVal, oldVal) {
      if (newVal == 'true' || newVal == true) {
        this.$router.push({ name: 'package-payment-pay',  params: { id: this.form._id } });
      }
    }
  },
  computed: {
    total_price() {
      let price = 0;
      this.form.product?.forEach(product => {
        price += parseFloat(product.product_price || 0);
      });
      return price;
    },
    total_discount() {
      let discount = 0;
      this.form.product?.forEach(product => {
        discount += parseFloat(product.discount || 0);
      });
      return discount;
    },
    vat_price() {
      let priceVat = 0;
      this.form.product?.forEach(product => {
        if (product.vat === 1) {
          priceVat += parseFloat(
            (product.product_price - product.discount) * 0.07 || 0
          );
        }
      });
      return priceVat;
    },
    withholding() {
      let priceVat = 0;
      this.form.product?.forEach(product => {
        priceVat += parseFloat(
          (product.product_price - product.discount) * 0.03 || 0
        );
      });
      return this.form.is_withholding ? priceVat : 0;
    },
    net_price() {
      return (
        this.total_price + this.vat_price - this.withholding - this.total_discount
      );
    }
  }
};
</script>

<style lang="scss">
.border-grey {
  border: solid rgb(211, 211, 211) 1px !important;
  border-radius: 5px;
  padding: 10px;
}
.border-white {
  border: solid rgb(255, 255, 255) 1px !important;
  border-radius: 5px;
  padding: 10px;
}
.swal2-icon.swal2-warning {
  border-color: #f27474 !important;
  icon-color: #f27474 !important;
}
.table-style {
  vertical-align: baseline !important;
}
.btn-table-style {
  vertical-align: top !important;
}
</style>
